import * as Prebid from 'Domain/version/web/provider/google/Prebid';
import Objects from 'Helper/object/Object';
import newRelicMetrics from 'Helper/metrics/BaxterNewRelicMetrics';
import { BaxterError } from 'Helper/metrics/BaxterError';
import { BaxterMetric } from 'Helper/metrics/BaxterMetric';
import { Slot } from 'Types/Slot';
import { Providers } from 'Domain/version/web/config/Providers';

export const id = Providers.GOOGLE_IMA;

export const webpackExclude = (config) => {
  const providerSettings = config.slots?.providerSettings?.[id] || {};
  const valuesDefault = (providerSettings.prebid?._ || {}) as Record<string, { enabled: boolean }>;
  const values = (providerSettings.prebid || {}) as Record<string, { enabled: boolean }>;
  return !(
    Object.values(valuesDefault).find((item) => Objects.isObject(item) && item?.enabled === true) ||
    Object.values(values).find((item) => Objects.isObject(item) && item?.enabled === true)
  );
};

export const init = () => {
  console.info('[SLOTS][GOOGLEIMAPREBID][INIT]');
  Prebid.init();
};

export const dependencies = () => {
  console.info('[SLOTS][GOOGLEIMAPREBID][DEPENDENCIES]');
  return Prebid.dependencies();
};

export const loaded = () => {
  console.info('[SLOTS][GOOGLEIMAPREBID][LOADED]');
  Prebid.loaded();
};

export const setTargeting = (targeting) => {
  console.info('[SLOTS][GOOGLEIMAPREBID][SETTARGETING]', targeting);
  Prebid.setTargeting(targeting);
};

export const transform = async (pageId, containerId, slotId, params): Promise<Slot> => {
  console.info('[SLOTS][GOOGLEIMAPREBID][TRANSFORM]', pageId, containerId, slotId, params);
  // @ts-ignore
  let slot = (await Prebid.transform(pageId, containerId, slotId, params, id)) as Record<string, unknown>;
  const providerSettings = globalThis.Baxter.config.slots?.providerSettings?.[id] || {};
  const prebidSettings =
    // @ts-ignore
    globalThis.Baxter.context.configurationService.getById(providerSettings.prebid, pageId, containerId, slotId) || {};
  if (prebidSettings.path) {
    slot = { ...slot, prebidPath: prebidSettings.path };
  }
  // @ts-ignore
  return slot;
};

const getPrebidExternal = (slot) => {
  const bids = Prebid.getBids(slot);
  // https://docs.prebid.org/dev-docs/adunit-reference.html
  return {
    mediaTypes: {
      video: {
        context: 'instream',
        playerSize: slot.prebidSizes,
        mimes: ['video/mp4', 'video/webm', 'video/3gpp'],
        protocols: [1, 2, 3, 4, 5, 6, 7, 8],
        playbackmethod: [2],
        skip: 1,
      },
    },
    ...(bids.path
      ? {
          code: slot.path,
          bids: bids.path,
        }
      : {
          code: slot.innerId,
          bids: bids.id,
        }),
  };
};

export const create = async (slot, loadVideo) => {
  console.info('[SLOTS][GOOGLEIMAPREBID][CREATE]', slot);
  const { pbjs } = window;
  if (pbjs) {
    const external = getPrebidExternal(slot);
    console.debug('[SLOTS][GOOGLEIMAPREBID][CREATE]', slot, external);
    pbjs.que.push(() => {
      try {
        pbjs.addAdUnits(external);
        pbjs.requestBids({
          adUnitCodes: [slot.innerId, slot.path],
          bidsBackHandler: (bids, timedOut) => {
            try {
              if (timedOut) {
                console.debug('[SLOTS][GOOGLEIMAPREBID][LOAD] timed out', bids);
                newRelicMetrics.reportMetric(BaxterMetric.PREBID_TIMED_OUT, {
                  command: '[GOOGLEIMAPREBIDLOAD]',
                });
              } else {
                console.debug('[SLOTS][GOOGLEIMAPREBID][LOAD] successfully returned', bids);
                newRelicMetrics.reportMetric(BaxterMetric.PREBID_SUCCESSFULLY_RETURNED, {
                  command: '[GOOGLEIMAPREBIDLOAD]',
                });
              }
              const adTagUrl = pbjs.adServers.dfp.buildVideoUrl({
                adUnit: external,
                params: {
                  iu: slot.path,
                  output: 'vast',
                },
              });
              pbjs.markWinningBidAsUsed({
                adUnitCode: external.code,
              });
              slot.prebidAdTagUrl = adTagUrl;
              loadVideo(slot);
            } catch (e) {
              console.error('[SLOTS][GOOGLEIMAPREBID][CREATE]', e);
              newRelicMetrics.reportError(BaxterError.PREBID_QUE_ERROR, {
                command: '[GOOGLEIMAPREBIDCREATE][BIDSBACKHANDLER]',
                message: (e as Error).message,
              });
              throw e;
            }
          },
        });
      } catch (e) {
        console.error('[SLOTS][GOOGLEIMAPREBID][CREATE]', e);
        newRelicMetrics.reportError(BaxterError.PREBID_QUE_ERROR, {
          command: '[GOOGLEIMAPREBIDCREATE]',
          message: (e as Error).message,
        });
        throw e;
      }
    });
  } else {
    console.error(`[SLOTS][GOOGLEIMAPREBID][CREATE]`);
    newRelicMetrics.reportError(BaxterError.PREBID_NO_PBJS, { command: '[GOOGLEIMAPREBIDCREATE]' });
  }
};

export const remove = (slots: Slot[] = []) => {
  console.info('[SLOTS][GOOGLEIMAPREBID][REMOVE]', slots);
  return Prebid.remove(slots, id);
};

export default {
  dependencies,
  init,
  loaded,
  transform,
  create,
  remove,
  setTargeting,
};
