import * as Strings from 'Helper/string/String';
import * as Objects from 'Helper/object/Object';
import * as Html from 'Helper/browser/Html';
import * as State from 'Domain/version/web/core/State';
import * as Request from 'Helper/browser/Request';
import { buildSlotRenderedEvent } from 'Domain/version/web/core/SlotRenderedEventListener';
import newRelicMetrics from 'Helper/metrics/BaxterNewRelicMetrics';
import { BaxterError } from 'Helper/metrics/BaxterError';
import { Providers } from 'Domain/version/web/config/Providers';
import { Slot } from 'Types/Slot';
import { TargetingParams } from 'Types/TargetingParams';
import { Config } from 'Types/Config';
import Placeholder from '../../feature/Placeholder';

export const id: Providers = Providers.BAXTER;

export const webpackExclude = (config: Config): boolean =>
  !(
    Object.values(config.slots.provider?._ ?? {}).includes(id) ||
    Object.values(config.slots.provider ?? {}).includes(id)
  );

export const transform = async (pageId: string, containerId: string, slotId: string, params: TargetingParams) => {
  console.info('[SLOTS][BAXTER][TRANSFORM]', pageId, containerId, slotId, params);
  const slot = {
    path: '',
    variables: {},
    targeting: {} as TargetingParams,
    styling: '',
  } as Partial<Slot>;
  const configService = globalThis.Baxter.context.configurationService;
  if (configService.hasTargetingFor(id)) {
    const targetingMap = configService.getTargetingMapFor(id, pageId, containerId, slotId);
    slot.targeting = Objects.parseMap(targetingMap, params || {}) as TargetingParams;
    slot.targeting.timestamp = new Date().getTime();
  }
  if (configService.hasVariablesFor(id)) {
    const variablesMap = configService.getVariablesMapFor(id, pageId, containerId, slotId);
    slot.variables = Object.fromEntries(variablesMap.map((variable) => [variable.key, variable.value]));
  }
  if (configService.hasPathFor(id)) {
    const pathMapping = configService.getPathMapFor(id, pageId, containerId, slotId);
    const pathString = Strings.parseMap(pathMapping, params || {});
    const parts = pathString.split('/');
    let sanitized = '';
    parts.forEach((part) => {
      if (part !== '') sanitized += `/${part}`;
    });
    slot.path = `/${configService.getProviderAccountId(id)}${sanitized}`;
  }

  return slot;
};

export const create = async (slot: Slot) => {
  console.info('[SLOTS][BAXTER][CREATE]', slot);
  const url = `${globalThis.Baxter.config.adsDomain}${slot.path}`;
  const div = Html.getElementById(slot.innerId);
  if (div) {
    div.innerHTML = '';
    div.style.display = '';

    if (!slot.status.lazyLoad || slot.status.visible) {
      slot.external = true;
      let html;
      try {
        html = await Request.get(url);
      } catch (err) {
        console.error('[SLOTS][BAXTER][CREATE] fetching ad error', err);
        newRelicMetrics.reportError(BaxterError.BAXTER_FETCH_AD_ERROR, {
          message: (err as Error).message,
          providerId: id,
          slotId: slot.id,
        });
      }
      if (html) {
        try {
          slot.filled = true;
          Object.keys(slot.variables || {}).forEach((key) => {
            const regex = new RegExp(`\\\${${key}\\}`, 'g');
            html = html.replace(regex, slot.variables?.[key] || '');
          });
          Object.keys(slot.targeting || {}).forEach((key) => {
            const regex = new RegExp(`\\\${targeting.${key}\\}`, 'g');
            html = html.replace(regex, slot.targeting?.[key] || '');
          });
          html = html.replace(/\$\{.*?\}/g, '');

          const trackingDiv = document.createElement('div');
          trackingDiv.id = `native_ad_frame_${slot.id}`;
          trackingDiv.innerHTML = html.replace(/<script[^>]*>(?:[^<]+|<(?!\/script>))*<\/script>/gi, '');

          div.appendChild(trackingDiv);

          const head = document.getElementsByTagName('head')[0];
          const parser = document.createElement('div');
          parser.innerHTML = html;
          const scripts = parser.getElementsByTagName('script');
          Object.keys(scripts || []).forEach((key) => {
            const js = scripts[key];
            const script = document.createElement('script');
            script.innerHTML =
              `try {\n${js.innerHTML}\n` +
              `} catch (err) {\n` +
              `  throw new Error('Advertising - Baxter | AccountId: ${globalThis.Baxter.config.accountId} | PageId: ${slot.pageId} | ContainerId: ${slot.containerId} | Slot: ${slot.id} | ' + err);\n` +
              `}`;
            script.type = 'text/javascript';
            script.async = true;
            head.appendChild(script);
          });

          Placeholder.remove(slot.containerId);
          window.dispatchEvent(
            buildSlotRenderedEvent(slot.pageId, slot.containerId, slot.id, slot.status, {}, false, id)
          );
          return html;
        } catch (e) {
          console.error('[SLOTS][BAXTER][CREATE] rendering html error', e);
          newRelicMetrics.reportError(BaxterError.BAXTER_RENDERING_ERROR, {
            message: 'rendering html error',
            pageId: slot.pageId,
            containerId: slot.containerId,
            providerId: id,
          });
          throw e;
        }
      } else {
        window.dispatchEvent(buildSlotRenderedEvent(slot.pageId, slot.containerId, slot.id, slot.status, {}, true, id));
        return slot.external;
      }
    } else {
      console.error('[SLOTS][BAXTER][CREATE] container not visible');
      newRelicMetrics.reportError(BaxterError.BAXTER_CONTAINER_NOT_VISIBLE, {
        message: 'container not visible',
        pageId: slot.pageId,
        containerId: slot.containerId,
        providerId: id,
      });
      throw new Error('[SLOTS] container not visible');
    }
  } else {
    console.error('[SLOTS][BAXTER][CREATE] container not found');
    newRelicMetrics.reportError(BaxterError.BAXTER_CONTAINER_DIV_NOT_FOUND, {
      message: 'container not found',
      pageId: slot.pageId,
      containerId: slot.containerId,
      providerId: id,
    });
    throw new Error('[SLOTS] container not found');
  }
};

export const refresh = async (slots: Slot[] = []): Promise<boolean> => {
  console.info('[SLOTS][BAXTER][REFRESH]', slots);
  const success = remove(slots);
  if (success) {
    await Promise.all(slots.map(async (slot) => create(slot)));
    return true;
  }
  return false;
};

export const remove = (slots: Slot[] = []): boolean => {
  console.info('[SLOTS][BAXTER][REMOVE]', slots);
  slots.forEach((slot) => {
    const div = Html.getElementById(slot.innerId);
    if (div) {
      div.innerHTML = '';
    }
  });
  return true;
};

export const clear = (): boolean => {
  console.info('[SLOTS][BAXTER][CLEAR]');
  const slots = Object.values(State.getSlots()).filter((slot) => slot.provider === id);
  return remove(slots);
};
